import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Parser from 'html-react-parser'

// Router
import { withRouter, useHistory } from 'react-router-dom'

// Components
import TabBar from 'components/elements/TabBar'
import PackageTemplate from './PackageTemplate'
import PackageHeader from './PackageHeader'
import PackageProtection from './PackageProtection'
import FAQAndTerm from './FAQAndTerm'
import PackageRemark from './PackageRemark'
import DetailHeader from 'components/layouts/DetailHeader'
import Button from 'components/elements/Button'
import Modal from 'components/elements/Modal'
import PopupISPVehicle from 'domain/Isp/components/PopupISPVehicle'

// CSS
import './index.scss'

// Constants
import { ISP_MASTER_NON_EXY, ISP_MASTER_BRONZE, ISP_MASTER_SILVER } from 'config/constant'
import { vehicleModalIcon } from 'config/icon'

const ISPPackageAds = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const handleActiveIndexUpdate = (activeIndex) => setActiveIndex(activeIndex)

  const ispVehicle = useSelector((state) => state.ispVehicle)

  const modalRef = useRef()
  const history = useHistory()

  const closeModal = () => modalRef.current.closeModal()

  const pathname = history.location.pathname

  const allVehicles = Object.values(ispVehicle.data)

  return (
    <div className='isp-package'>
      <PackageHeader />
      {pathname !== '/isp/no-vehicle' && pathname !== '/isp-about' && <DetailHeader showBack type='basic' />}
      <Modal
        mode={8}
        title={'privilege.vehicleModal'}
        content={
          <PopupISPVehicle
            vehicles={ispVehicle}
            ispList={allVehicles}
            close={closeModal}
            isp={true}
            clickClose={true}
            ispDetailPage={true}
          />
        }
        ref={modalRef}
      />

      {ispVehicle.total_count > 1 && (
        <Button
          className='isp-btn'
          icon={vehicleModalIcon}
          label='privilege.vehicleModal'
          type='vehicle'
          onClick={() => {
            modalRef.current.openModal()
          }}
        />
      )}
      <div className='isp-package__isp'>
        <PackageProtection />
        <div className='isp-package__isp__ads'>
          <div>
            <TabBar
              tabs={[Parser(ISP_MASTER_NON_EXY.tabTitle), Parser(ISP_MASTER_BRONZE.tabTitle), Parser(ISP_MASTER_SILVER.tabTitle)]}
              onActiveIndexChange={handleActiveIndexUpdate}
              isPackage
            />

            <div className='isp-package__ads'>
              {activeIndex === 0 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_NON_EXY} type='normal' />
                </div>
              )}
              {activeIndex === 1 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_BRONZE} type='bronze' />
                </div>
              )}
              {activeIndex === 2 && (
                <div className='bg-container'>
                  <PackageTemplate data={ISP_MASTER_SILVER} type='silver' />
                </div>
              )}
            </div>
          </div>
        </div>
        <FAQAndTerm />
        <PackageRemark />
      </div>
    </div>
  )
}

export default withRouter(ISPPackageAds)
