import React, { useState, useRef } from 'react'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import ReactTooltip from 'react-tooltip'

// Components
import { Information } from './Information'

// Method
import { formatNumberWithDecimal } from 'utils/format'
import { infoIcon } from 'config/icon'

const RepairDetailBottom = (props) => {
  const detail = props.RepairDetail

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const tooltipRef = useRef()

  const setTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  return (
    <React.Fragment>
      <div className='repair-detail__total-price'>
        {isTooltipOpen && <div id='tooltip-overlay' onClick={setTooltip} />}
        {getLabel('net', detail.total_net_fee, detail.is_icare)}
        {getLabel('vat', detail.total_vat_fee, detail.is_icare)}
        {getLabel('total', detail.total_price, detail.is_icare, setTooltip, tooltipRef, isTooltipOpen)}
      </div>
    </React.Fragment>
  )
}

export default RepairDetailBottom

const getLabel = (text, price, icare, setTooltip, ref, isTooltipOpen) => {
  return (
    <React.Fragment>
      <div className={`repair-detail__total-price__${text}`}>
        <div className={`repair-detail__total-price__text-${text}`}>
          <FormattedHTMLMessage id={`RepairHistory.RepairDetail.${text}`} defaultMessage={`RepairHistory.RepairDetail.${text}`} />
          {text === 'total' && (
            <div className='info-icon' onClick={setTooltip}>
              <i data-tip data-for='info' ref={ref}>
                {infoIcon}
              </i>
              <ReactTooltip place='top' type='light' effect='solid' id='info' className='info-tooltip' clickable={true}>
                <div className='mobile-tablet'>{isTooltipOpen ? Information(setTooltip) : <div></div>}</div>
                <div className='desktop'>{Information(setTooltip)}</div>
              </ReactTooltip>
            </div>
          )}
        </div>
        <div className={`repair-detail__total-price__value-${text}`}>
          {!icare && price && price > 0 ? (
            <FormattedHTMLMessage
              id='RepairHistory.RepairDetail.price'
              values={{
                price: formatNumberWithDecimal(+price, 2)
              }}
              defaultMessage='RepairHistory.RepairDetail.price'
            />
          ) : (
            <FormattedMessage id='RepairHistory.RepairDetail.noFee' defaultMessage='RepairHistory.RepairDetail.noFee' className='values' />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
