import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import * as datadogRum from 'utils/datadog'

// Redux
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Router
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

// Check Authentication
import { checkAuth } from 'utils/auth'
import { authenticated, notAuthenticated } from 'utils/policies'

// Validation Regex
import { isMobileApp } from 'utils/validation'

import { checkError, openMyISUZUApp } from 'utils/helper'

// Components
import Alert from 'components/elements/Alert'
import Loader from 'components/sections/Loader'
import Add2Home from 'components/elements/Add2Home'

// Classnames
import classnames from 'classnames'

// Views
import Navigation from 'domain/Navigation'
import Top from 'domain/Top'
import ScrollToTop from 'components/elements/ScrollToTop'
import Home from 'domain/Home'
import Vehicles from 'domain/Vehicle'
import VehicleAdd from 'domain/Vehicle/VehicleAdd'
import VehicleSearch from 'domain/Vehicle/VehicleSearch'
import VehicleEdit from 'domain/Vehicle/VehicleEdit'
import VehicleDetail from 'domain/Vehicle/VehicleDetail'
import Coupons from 'domain/Coupons'
import Promotions from 'domain/Promotions'
import PromotionDetail from 'domain/Promotions/PromotionDetail'
import ChatPage from 'domain/ChatPage'
import Profile from 'domain/Profile'
import Privileges from 'domain/Privileges'
import ExcellencyConditions from 'domain/Excellency/ExcellencyConditions'
import ExcellencyDetail from 'domain/Excellency/ExcellencyDetail'
import IspDetail from 'domain/Isp/IspDetail'
import ISPPackageAds from 'domain/Isp/components/ISPPackageAds'
import DealerSearch from 'domain/DealerSearch'
import ProfileEdit from 'domain/Profile/ProfileEdit'
import ProfileSettings from 'domain/Profile/ProfileSettings'
import ProfileChangePassword from 'domain/Profile/ProfileChangePassword'
import Controllers from 'domain/Controllers'
import ControllerAdd from 'domain/Controllers/ControllerAdd'
import ControllerDetail from 'domain/Controllers/ControllerDetail'
import HelpPage from 'domain/HelpPage'
import Signin from 'domain/Signin'
import SigninIssue from 'domain/Signin/SigninIssue'
import Signup from 'domain/Signup'
import CreateAccount from 'domain/CreateAccount'
import Page404 from 'domain/404'
import AutoLogin from 'domain/AutoLogin'
import AssignController from 'domain/AssignController'
import DeleteVehicles from 'domain/Vehicle/DeleteVehicles'
import UnassignController from 'domain/UnassignController'
import TermsConditions from 'domain/TermsConditions'
import PrivacyPolicies from 'domain/PrivacyPolicy'
import TILRefinance from 'domain/TILRefinance'
import TokenExpire from 'domain/CreateAccount/TokenExpire'
import T2H from 'domain/T2H'
import CompanyList from 'domain/Company/CompanyList'
import CompanyAdd from 'domain/Company/CompanyAdd'
import Setting from 'domain/Setting'
import RepairHistory from 'domain/RepairHistory'
import RepairDetail from 'domain/RepairHistory/components/RepairDetail'
import AppLanding from 'domain/AppLanding'
import NoInternetConnection from 'domain/NoInternetConnection'
import { InternalTestError } from 'domain/InternalTestError'
import { MyiTermsAndConditions } from 'domains/termsAndConditions/components/MyiTermsAndConditions'
import { MuxTheExclusive } from 'domain/MuxTheExclusive'

/* For ISP POC only */
import ISPPackage from 'domain/Isp/components/ISPPackageAds'
import ISPSelection from 'domain/Isp/components/ISPSelection'

// Constants
import { USER_TYPE } from 'config/constant'

// i18
import { IntlProvider } from 'react-intl'
import { messages } from '../../lang'

// Styles
import './_index.scss'

// Actions
import { getProfile, getProfileStat, postClick, skipCustomerProfileLoading } from 'store/actions/profile'
import { getControllersList } from 'store/actions/controllers'
import { getMasters } from 'store/actions/master'
import { getVehicleLength } from 'store/actions/vehicles'

import isEmpty from 'lodash/isEmpty'

import { isLINEBrowser } from 'utils/validation'

// Hooks
import useNetwork from 'hooks/useNetwork'

import { appLink } from 'config/environment'
import CreatePassword from '../CreatePassword'
import { MyiPrivacyPolicy } from 'domains/privacyPolicy/components/MyiPrivacyPolicy'

import queryString from 'query-string'

import { isAndroid } from 'react-device-detect'

const Wrapper = (props) => {
  const history = useHistory()
  const profile = useSelector((state) => state.profile)
  const isOnline = useNetwork()

  // FIXME: Ideally we should have a mechanism to switch between layouts, and
  // Wrapper component should contains only react-router logic, no CSS, no API
  // calling at all. When authenticated, we switch to authenticated layout which
  // will call essential APIs and always render top navbar. On login page or
  // standalone pages like Terms and Conditions, we use blank layout, so that we
  // do not have to deal with unrelated logic from authenticated layout.
  const isStandalonePage = window.location.pathname.startsWith('/terms/') || window.location.pathname.startsWith('/privacy/')

  useEffect(() => {
    // Only log an error if it's not a "script error". This error type is not useful as it doesn't contain any
    // information about what went wrong. The details are blocked by the browser for security reasons.
    // See https://udn.realityripple.com/docs/Web/API/GlobalEventHandlers/onerror under the Notes section
    // See #2 https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#script-error
    window.onerror = function (message, url, line, column, error) {
      const formattedMessage = message.toLowerCase()
      if (formattedMessage.indexOf('script error') < 0) {
        console.error(
          {
            message,
            url,
            line,
            column,
            error: error.stack ? error.stack : error
          },
          error
        )
      }
    }

    const readyToInstall = (e) => {
      e.preventDefault()
      window.deferredPrompt = e
    }

    window.addEventListener('beforeinstallprompt', (e) => readyToInstall(e))

    return () => {
      window.removeEventListener('beforeinstallprompt', (e) => readyToInstall(e))
    }
  }, [])

  useEffect(() => {
    if (isOnline && checkAuth()) {
      Promise.all([props.getMasters(), props.getProfile(), props.getProfileStat(), props.getVehicleLength()]).catch((err) => {
        checkError(err)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, props.getMasters, props.getProfile, props.getProfileStat, props.getVehicleLength])

  useEffect(() => {
    if (isEmpty(profile)) return
    datadogRum.setCustomer(profile.id, profile.type, profile.subtype, profile.isDemoUser)
  }, [profile])

  useEffect(() => {
    if (props.postClick) props.postClick()
    if (props.profile && props.profile.type === 2) getControllersList()
    if (props.scale)
      document
        .querySelector('html')
        .setAttribute('style', `font-size: ${props.scale === 'small' ? '55%' : props.scale === 'large' ? '70%' : '62.5%'} !important`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile, props.scale, props.postClick])

  useEffect(() => {
    const path = window.location.pathname
    const isNotTilRefinance = window.location.href.indexOf('til-refinance') === -1
    const searchParams = new URLSearchParams(props.searchParams)
    searchParams.append('redirect', path)

    const paramsObject = {};
    for (const [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }

    if (isLINEBrowser(navigator) && profile.loadProfileComplete) {
      if (isAndroid) {
        if (isNotTilRefinance) {
          openMyISUZUApp(profile.line_uid && `/signin/line/${profile.line_uid}`, paramsObject)
        }
      } else {
        const line_link = profile.line_uid ? `${appLink}signin/line/${profile.line_uid}` : `${appLink}`
        const link = queryString.stringifyUrl({
          url: line_link,
          query: paramsObject
        })
        window.location.href = path ? link : line_link
      }
    }
  }, [history, profile])

  const authPage = (Page) => authenticated(Page)
  const notAuthenticatedPage = (Page) => notAuthenticated(Page)

  const classes = classnames('wrapper', {
    'wrapper--no-auth':
      !checkAuth() ||
      window.location.pathname === '/create-account' ||
      window.location.pathname === '/signup' ||
      window.location.pathname === '/vehicles/search' ||
      window.location.pathname === '/signin/issue' ||
      window.location.pathname === '/signin',
    'wrapper--full-page': window.location.pathname === '/signin' || isStandalonePage,
    'wrapper--standalone-page': isStandalonePage
  })
  const desktopClassess = classnames('desktop', {
    'desktop--no-auth': !checkAuth(),
    'desktop--no-auth--signin': window.location.pathname === '/signin',
    'desktop--no-auth--signup': window.location.pathname === '/signup'
  })

  // Use enhanced create account component to pass userType props via component
  const EnhancedCreateAccount = notAuthenticated(CreateAccount)

  return (
    <IntlProvider locale={props.lang || 'th'} messages={messages[props.lang || 'th']}>
      <div className={desktopClassess}>
        <div className='desktop__wrapper'>
          <div className='desktop__wrapper__container'>
            <div className={classes}>
              <Router>
                <ScrollToTop>
                  <div className='wrapper__route synthetic-test___wrapper__route'>
                    {isStandalonePage ? null : <Add2Home />}
                    {isStandalonePage ? null : <Top />}
                    {isStandalonePage ? null : <div className='desktop__menu__nav'>{!isMobileApp() && <Navigation />}</div>}
                    {isOnline ? (
                      <Switch>
                        <Route exact path='/' component={authPage(Home)} />
                        <Route exact path='/_error' component={InternalTestError} />
                        <Route exact path='/terms/:locale' component={MyiTermsAndConditions} />
                        <Route exact path='/privacy/:locale' component={MyiPrivacyPolicy} />
                        <Route exact path='/vehicles' component={authPage(Vehicles)} />
                        <Route exact path='/vehicles/search' component={authPage(VehicleSearch)} />
                        <Route exact path='/vehicle/add' component={authPage(VehicleAdd)} />
                        <Route exact path='/vehicle/edit/:id' component={authPage(VehicleEdit)} />
                        <Route exact path='/vehicle/:id' component={authPage(VehicleDetail)} />
                        <Route exact path='/vehicles/assign-controller' component={authPage(AssignController)} />
                        <Route exact path='/vehicles/unassign-controller' component={authPage(UnassignController)} />
                        <Route exact path='/vehicles/delete' component={authPage(DeleteVehicles)} />
                        <Route exact path='/coupons' component={authPage(Coupons)} />
                        <Route exact path='/promotions' component={authPage(Promotions)} />
                        <Route exact path='/promotion/:id' component={authPage(PromotionDetail)} />
                        <Route exact path='/chat' component={authPage(ChatPage)} />
                        <Route exact path='/profile' component={authPage(Profile)} />
                        <Route exact path='/profile/edit' component={authPage(ProfileEdit)} />
                        <Route exact path='/profile/settings' component={authPage(ProfileSettings)} />
                        <Route exact path='/profile/change-password' component={authPage(ProfileChangePassword)} />
                        <Route exact path='/privilege' component={authPage(Privileges)} />
                        <Route exact path='/excellency/:id/privileges-conditions' component={authPage(ExcellencyConditions)} />
                        <Route exact path='/excellency/:id' component={authPage(ExcellencyDetail)} />
                        <Route exact path='/isp/:id' component={authPage(IspDetail)} />
                        <Route exact path='/dealer-search' component={authPage(DealerSearch)} />
                        <Route exact path='/controllers' component={authPage(Controllers)} />
                        <Route exact path='/controller/add' component={authPage(ControllerAdd)} />
                        <Route exact path='/controller/:id' component={authPage(ControllerDetail)} />
                        <Route exact path='/help-page' component={authPage(HelpPage)} />
                        <Route exact path='/signin' component={notAuthenticatedPage(Signin)} />
                        <Route exact path='/signin/issue' component={SigninIssue} />
                        <Route exact path='/signup' component={Signup} />
                        <Route exact path='/create-account' component={CreateAccount} />
                        <Route exact path='/404' component={authPage(Page404)} />
                        <Route exact path='/auto-login' component={notAuthenticatedPage(AutoLogin)} />
                        <Route exact path='/terms-conditions' component={TermsConditions} />
                        <Route exact path='/privacy-policies' component={authPage(PrivacyPolicies)} />
                        <Route exact path='/create-account/expire' component={notAuthenticatedPage(TokenExpire)} />
                        <Route exact path='/T2H/:id' component={authPage(T2H)} />
                        <Route exact path='/company/list' component={authPage(CompanyList)} />
                        <Route exact path='/company/add' component={authPage(CompanyAdd)} />
                        <Route exact path='/setting' component={authPage(Setting)}></Route>

                        <Route exact path='/repair-history/:id' component={authPage(RepairHistory)} />
                        <Route exact path='/repair-history/:id/detail/:al' component={authPage(RepairDetail)} />

                        <Route exact path='/til-refinance/:id' component={authPage(TILRefinance)} />
                        <Route exact path='/til-refinance' component={authPage(TILRefinance)} />

                        {/* For ISP POC only */}
                        <Route exact path='/isp-pack' component={authPage(ISPPackage)} />
                        <Route exact path='/isp-selection/:id' component={authPage(ISPSelection)} />
                        <Route
                          exact
                          path='/corporate/signup'
                          render={(props) => <EnhancedCreateAccount {...props} userType={USER_TYPE.CORPORATE} />}
                        />

                        <Route exact path='/isp-about' component={authPage(ISPPackageAds)} />
                        <Route exact path='/myisuzu-app' component={AppLanding} />
                        {/**
                         * The path "create-password" must match the application's deep link path to the
                         * screen where the user sets their password.
                         * See: https://tripetch.atlassian.net/browse/LEGO-5393?focusedCommentId=49310
                         * // TODO: Ideally, maybe edit the apple-app-site-association and assetlinks.json to ignore any specific path in our app (e.g. /create-pw) and redirect to whatever path we want in the app.
                         */}
                        <Route exact path='/create-password' component={CreatePassword} />
                        <Route exact path='/mux-the-exclusive' component={authPage(MuxTheExclusive)} />
                        <Route exact path='/timeline/:postId'>
                          <Redirect to='/signup' />
                        </Route>
                        <Route exact path='/applink' component={Signup} />
                        <Redirect to='/404' />
                      </Switch>
                    ) : (
                      <NoInternetConnection />
                    )}
                    <Alert />
                    <Loader />
                  </div>
                </ScrollToTop>
              </Router>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  )
}

export const mapStateToProps = (state) => ({
  profile: state.profile,
  lang: state.config.lang,
  scale: state.config.scale,
  searchParams: state.router.location.search,
  vehicleLength: state.vehicleLength.length,
  vehicleId: state.vehicleLength.id
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        getProfile,
        getProfileStat,
        getControllersList,
        getMasters,
        postClick,
        getVehicleLength,
        skipCustomerProfileLoading
      },
      dispatch
    )

Wrapper.propTypes = {
  getProfile: PropTypes.func,
  getProfileStat: PropTypes.func,
  profile: PropTypes.object,
  getControllersList: PropTypes.func,
  getMasters: PropTypes.func,
  lang: PropTypes.string,
  scale: PropTypes.string,
  getVehicleLength: PropTypes.func,
  skipCustomerProfileLoading: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wrapper))
